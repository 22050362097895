import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Menu()
{
    return(
        <Fragment>
           <header className="header_section">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
          <Link className="navbar-brand" to="/">
            <span>
            Sanyuktatech
            </span>
          </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className=""> </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  ">
              <li className="nav-item active">
                <Link className="nav-link" to="/">Home</Link>
               
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/service">Services</Link>
              </li>
              <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="quote_btn-container">
              <a href="" className="quote_btn">
                Get A Quote
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
        </Fragment>
    )
}

export default Menu;