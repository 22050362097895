import React, { Fragment } from "react";
import Menu from "./Menu";

function About()
{
    return(
        <Fragment>
          <div className="sub_page" >
            <div className="hero_area">
   
   <Menu />
   
  </div>



  <section className="about_section layout_padding layout_margin">
    <div className="container  ">
      <div className="row">
        <div className="col-md-6">
          <div className="detail-box">
            <div className="heading_container">
              <h2>
                About Us
              </h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour, or randomised words which don't look even slightly believable. If you
              are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in
              the middle of text. All
            </p>
            <a href="">
              Read More
            </a>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="img-box">
            <img src="images/about-img.png" alt="" />
          </div>
        </div>

      </div>
    </div>
  </section>

 

  <div className="footer_container">
    

    <section className="info_section ">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 ">
            <div className="info_detail">
              <h4>
                Digian
              </h4>
              <p>
                Necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-2 mx-auto">
            <div className="info_link_box">
              <h4>
                Links
              </h4>
              <div className="info_links">
                <a className="" href="index.html">
                  Home
                </a>
                <a className="" href="about.html">
                  About
                </a>
                <a className="" href="service.html">
                  Services
                </a>
                <a className="" href="contact.html">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 ">
            <h4>
              Subscribe
            </h4>
            <form action="#">
              <input type="text" placeholder="Enter email" />
              <button type="submit">
                Subscribe
              </button>
            </form>
          </div>
          <div className="col-md-6 col-lg-3 mb-0 ml-auto">
            <div className="info_contact">
              <h4>
                Address
              </h4>
              <div className="contact_link_box">
                <a href="">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  <span>
                    Location
                  </span>
                </a>
                <a href="">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span>
                    Call +01 1234567890
                  </span>
                </a>
                <a href="">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>
                    demo@gmail.com
                  </span>
                </a>
              </div>
            </div>
            <div className="info_social">
              <a href="">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer className="footer_section">
      <div className="container">
        <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <a href="https://html.design/">Free Html Templates</a>
        </p>
      </div>
    </footer>
   
  </div>

 
  <script src="js/jquery-3.4.1.min.js"></script>
 
  <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous">
  </script>
  
  <script src="js/bootstrap.js"></script>
  <script src="js/custom.js"></script>
 
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCh39n5U-4IoWpsVGUHWdqB6puEkhRLdmI&callback=myMap"></script>
 </div>
        </Fragment>
    )
}

export default About;