
import Index from './pages/Index';
import About from './pages/About';
import Contact from './pages/Contact';
import Service from './pages/Service';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
    return ( 
        <div className = "App" >
      <Router>
        <Routes>
           <Route path='/' element={<Index />} />
           <Route path='/about' element={<About />} />
           <Route path='/contact' element={<Contact />} />
           <Route path='/service' element={<Service />} />
        </Routes>
      </Router>
         </div>
    );
}

export default App;